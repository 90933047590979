import { useEffect, useState } from "react";
import "./App.css";
import axios from "axios";
import { Dialog } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

ChartJS.defaults.color = "#fff";

const sort = (group) => {
  switch (group) {
    case "tulaj":
      return 0;
    case "fejleszto":
      return 1;
    case "szvez":
      return 2;
    case "commanager":
      return 3;
    case "eventmanager":
      return 4;
    case "adminc":
      return 5;
    case "hadmin":
      return 6;
    case "admin3":
      return 7;
    case "admin2":
      return 8;
    case "admin1":
      return 9;
    case "helper":
      return 10;
    default:
      return 11;
  }
};

const getRankName = (rank) => {
  switch (rank) {
    case "owner":
      return "Owner";
    case "servermanager":
      return "Server Manager";
    case "developer":
      return "Developer";
    case "communitymanager":
      return "Community Manager";
    case "factionmanager":
      return "Faction Manager";
    case "admincontroller":
      return "Admin Controller";
    case "headadmin":
      return "Head Admin";
    case "admin5":
      return "Admin V";
    case "admin4":
      return "Admin IV";
    case "admin3":
      return "Admin III";
    case "admin2":
      return "Admin II";
    case "admin1":
      return "Admin I";
    case "moderator":
      return "Moderator";
    case "eventmanager":
      return "Event Manager";
    default:
      return "User";
  }
};

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Admin duty",
    },
  },
};

function App() {
  const [admins, setAdmins] = useState([]);
  const [open, setOpen] = useState(false);
  const [activeAdmin, setActiveAdmin] = useState();
  const [reports, setReports] = useState(0);
  const [dutytime, setDutyTime] = useState([]);
  const [dutySum, setDutySum] = useState(0);
  const [topList, setTopList] = useState([])

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    let duty = 0;
    dutytime.forEach((num) => {
      duty += num.time / 60;
    });

    setDutySum(duty);
  }, [dutytime]);

  const openDialog = (admin) => {
    setActiveAdmin(admin);
    setOpen(true);

    axios
      .post("https://api.nightcityrp.hu:8669/getAdminReports", {
        license: admin.license,
        license2: admin.ids
          .filter((id) => id.includes("license2"))[0]
          ?.replace("license2:", ""),
      })
      .then((response) => {
        if (response.data.length === 0) {
          setReports(0);
          return;
        }

        setReports(response.data[0].responded_reports);
      });

    axios
      .post("https://api.nightcityrp.hu:8669/getAdminDutyTime", {
        license: admin.license,
        license2: admin.ids
          .filter((id) => id.includes("license2"))[0]
          ?.replace("license2:", ""),
      })
      .then((response) => {
        const duty = response.data.filter(
          (time) =>
            new Date(time.date).getTime() >= startDate.getTime() &&
            new Date(time.date).getTime() <= endDate.getTime()
        );

        let time = 0;

        response.data.forEach((tims) => {
          time += tims.time;
        });

        console.log(time / 60);

        let times = [];

        duty.forEach((time) => {
          const dateTime = new Date(time.date);
          const day = `${dateTime.getFullYear()}-${
            dateTime.getMonth() + 1
          }-${dateTime.getDate()}`;

          const isDateAvaliable = times.some(
            (timeData) => timeData.day === day
          );

          if (isDateAvaliable) {
            times = times.map((timeData) => {
              if (timeData.day === day) {
                return { ...timeData, time: timeData.time + time.time };
              } else {
                return { ...timeData };
              }
            });
          } else {
            times.push({ day: day, time: time.time });
          }
        });
        setDutyTime(times);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios
      .post("https://api.nightcityrp.hu:8669/getAdmins")
      .then((response) =>
        setAdmins(
          response.data.sort((a, b) => (a.group > b.group ? 1 : -1))
        )
      );

    axios
      .post("https://api.nightcityrp.hu:8669/getAdminTopList")
      .then((response) => {setTopList(response.data)});
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="App">
        <div className="wrapper">
          <div className="box">
            <div className="formWrapper">
              <DatePicker
                label="Kezdő dátum"
                onChange={(e) => setStartDate(new Date(e.$d))}
              />
              <DatePicker
                label="Vég dátum"
                onChange={(e) => setEndDate(new Date(e.$d))}
              />
            </div>

            <div className="title">Toplista</div>

            <div className="toplist">
              {topList.map((admin, idx) => {
                return(
                  <div className="item">
                    <div className="number">{idx + 1}.</div>
                    {admins.filter((adminData) => adminData.license.includes(admin.admin_identifier))[0]?.displayName} | {admin.responded_reports}
                  </div>
                )
              })}

            </div>

            <div className="title">Staff Team</div>
            <div className="admins">
              {admins.map((admin) => {
                if (admin.displayName === undefined) {
                  return;
                }

                return (
                  <div
                    key={admin.displayName}
                    className="item"
                    onClick={() => openDialog(admin)}
                  >
                    <div className="name">{admin.displayName}</div>
                    <div className={`label ${admin.group}`}>
                      {getRankName(admin.group)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <Dialog onClose={handleClose} open={open}>
          <div className="dialog">
            <div className="title">{activeAdmin?.displayName}</div>
            <div className="data">
              <div className="option">License:</div>
              <div className="label">license:{activeAdmin?.license}</div>
            </div>
            <div className="data">
              <div className="option">Rang:</div>
              <div className="label">{getRankName(activeAdmin?.group)}</div>
            </div>
            <div className="data">
              <div className="option">Reportok:</div>
              <div className="label">{reports}</div>
            </div>
            <div className="data">
              <div className="option">Duty idő:</div>
              <div className="label">{Math.floor(dutySum)} perc</div>
            </div>
            <Line
              color="#fff"
              options={options}
              data={{
                labels: dutytime.map((time) => time.day),
                datasets: [
                  {
                    fill: true,
                    label: "Duty idő percben",
                    data: dutytime.map((time) => time.time / 60),
                    borderColor: "rgb(105, 111, 251)",
                    backgroundColor: "rgba(105, 111, 251, 0.2)",
                  },
                ],
              }}
            />
          </div>
        </Dialog>
      </div>
    </LocalizationProvider>
  );
}

export default App;
